<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Dokumen </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateDokumen">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="nama_dokumen"
                  label="Nama Dokumen"
                  placeholder="Input Nama Dokumen"
                />
              </CCol>
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Kategori</label>
                  <select class="form-control" v-model="id_kategori">
                    <option value="" disabled selected>-- Pilih Kategori --</option>
                    <option v-for="r in kategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>  -->
              <CCol sm="6">
                <div class="form-group">
                  <label>File Dokumen</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto1">
                  </div>
                </div>  
              </CCol>
              <!-- <CCol sm="6">
                <CInput
                  v-model="tgl_posting"
                  type="date"
                  label="Tanggal Posting"
                  placeholder="Input Tanggal Posting"
                />
              </CCol> -->
            </CRow>        
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/dokumen">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>   
</template>
<script>

import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nama_dokumen : "",
      // tgl_posting : "",
      nama_file_text : "",
      nama_file : "",
      foto1 : "",
      placeholder : "Pilih File",
      selected: [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  // this.loadData();
  this.showDokumen();
  },
  methods: {

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"api/public/kategoris/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
    //     .then((response) => {
    //           this.kategori=response.data;
    //     });
    // },

    onFileChange(event) {
      var fileData = event.target.files[0];
      this.nama_file_text = fileData.name;
      this.nama_file = fileData;
      this.placeholder = fileData.name;
    },

    updateDokumen: function(event){

      // const dokumen = { 
      //                 nama_dokumen:this.nama_dokumen,
      //                 nama_file: this.nama_file,
      //                 id_dokumen: this.$route.params.id_dokumen
      //               };
      
      const formData = new FormData()
        formData.append('id_dokumen', this.$route.params.id_dokumen)
        formData.append('nama_dokumen', this.nama_dokumen)
        // formData.append('tgl_posting', this.tgl_posting)
        formData.append('foto1', this.foto1)

        if (this.nama_file_text) {
          formData.append('nama_file', this.nama_file, this.nama_file.name) 
          formData.append('nama_file_text', this.nama_file.name)
        }
      
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"dokumen/"+this.$route.params.id_dokumen, formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/dokumen');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    
    showDokumen: function(event){
      // Simple POST request with a JSON body using axios
      const dokumen = { 
                    nama_dokumen:this.nama_dokumen,
                    nama_file: this.nama_file
                    };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"dokumen/"+this.$route.params.id_dokumen)
        .then((response) => {
            
              this.nama_dokumen=response.data.nama_dokumen;
              this.placeholder=response.data.nama_file;
              this.foto1=response.data.nama_file;            
        })
    }
  }
}
</script>